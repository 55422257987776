<template>
  <v-container class="party-invite pa-0" fluid>
    <v-card v-if="loading"> </v-card>
    <v-card
      v-else-if="visible"
      flat
      class="mx-auto"
      max-width="500"
      style="position: relative"
    >
      <div ref="ticketContainer">
        <v-btn
          style="
            position: absolute;
            top: 15px;
            left: 15px;
            z-index: 9;
            background-color: white;
          "
          @click="$router.push('/')"
          v-if="showForwardButton"
          icon
          x-large
        >
          <v-icon color="black">mdi-chevron-left</v-icon>
        </v-btn>
        <v-img
          v-if="showForwardButton"
          src="https://app.wellcomefit.ch/img/news/party.jpg"
        ></v-img>
        <v-card-title class="text-h5 font-weight-bold primary--text">
          well come FIT Party
        </v-card-title>
        <v-card-subtitle class="text-h6 font-weight-bold text-green">
          ALLES AUF GRÜN
        </v-card-subtitle>
        <v-card-text class="pb-0">
          <p class="text-body-1">
            Freitag, 13. Dezember 2024<br />
            <strong>Start:</strong> 20:00 Uhr<br />
            <strong>Wo:</strong> Dreiegg, Metzgerstrasse 4, 8500 Frauenfeld<br />
            <strong>Dresscode:</strong> SOMETHING GREEN<br />
            <span
              ><strong>Getränke:</strong> werden mit wcf Dollar bezahlt
              (Zahlmittel wird am Eingang übergeben)</span
            >
          </p>
          <v-divider v-if="!isRegistered" class="my-4"></v-divider>
          <div v-if="!isRegistered">
            <p v-if="isEmployee" class="text-body-1 my-5">
              Wir haben so viele Gründe gemeinsam zu feiern. Deshalb lade ich
              dich herzlich mit deiner Begleitung zu unserer Party ein. 🎉<br />
            </p>
            <p v-else class="text-body-1 my-5">
              Wir haben so viele Gründe gemeinsam zu feiern. Deshalb lade ich
              dich herzlich zu unserer Party ein. 🎉<br />
            </p>
          </div>
        </v-card-text>
        <v-divider class="my-4"></v-divider>

        <!-- Bedingung: Eintrittskarte anzeigen, wenn der Benutzer bereits angemeldet ist -->
        <v-card-text v-if="isRegistered" class="party-ticket pa-4 text-center">
          <v-row align="center" class="mb-0">
            <v-col cols="12" class="text-h4 font-weight-bold primary--text">
              DEINE EINTRITTSKARTE
            </v-col>
            <v-col cols="12" class="text-body-1 black--text">
              <p>
                🎉 Hey {{ user.firstName }} {{ user.lastName }}, Du bist
                <strong>verbindlich</strong> angemeldet! 🎉<br />
                <span class="mt-2" style="color: green; font-size: 1.1em"
                  >Wir freuen uns auf dich!</span
                >
              </p>
            </v-col>
            <v-col
              class="my-0 py-0"
              v-if="showForwardButton"
              align="center"
              cols="12"
            >
              <v-btn ref="forwardButton" @click="forwardTicket" outlined small>
                Ticket herunterladen
              </v-btn>
            </v-col>
            <v-col v-if="isEmployee" cols="12" class="text-body-1 my-2">
              <v-divider></v-divider>
              <p class="text-h5 mt-2 font-weight-medium">
                <strong>Begleitung:</strong> {{ plusOne || "keine" }}
              </p>

              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-body-2 text-muted font-weight-bold">
              <em
                >Die Teilnehmerzahl ist auf 500 Personen beschränkt.<br />
                Ohne Ticket kein Einlass.</em
              >
            </v-col>
            <v-col cols="12" class="text-center">
              <p class="text-body-2 mb-0 mt-2">
                Abmeldung bis zwei Wochen vor der Party möglich
              </p>
            </v-col>
          </v-row>
          <v-row v-if="showForwardButton" align="center" class="mb-4">
            <v-col cols="12" class="text-center pt-0">
              <v-btn text @click="signOff">Ich bin leider verhindert</v-btn>
              <v-btn
                v-if="
                  isEmployee &&
                  plusOne &&
                  plusOne.length > 2 &&
                  plusOne !== 'keine'
                "
                text
                @click="signOffPlusOne"
                class="mt-2"
                >Meine Begleitung ist leider verhindert</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Anmeldeformular, falls Benutzer noch nicht angemeldet ist -->
        <v-card-text v-else>
          <v-row align="center" class="mb-4">
            <v-col cols="12" class="text-h6 font-weight-bold text-center pb-0">
              Hey, {{ user.firstName }}!
            </v-col>
            <v-col v-if="isEmployee" cols="12" class="text-body-1 text-center">
              Bitte gib hier den Namen deiner Begleitung ein. Wenn Du alleine
              kommst einfach leer lassen
            </v-col>
          </v-row>
          <!-- Zeige das Textfeld nur, wenn der Benutzer ein Mitarbeiter ist -->
          <v-text-field
            v-if="isEmployee"
            label="Vor- und Nachname der Begleitung (optional)"
            v-model="plusOne"
            outlined
            clearable
          ></v-text-field>

          <v-row>
            <v-col align="center" cols="12">
              <v-card-text
                >noch {{ remainingSlots }} Plätze verfügbar!</v-card-text
              >
              <v-btn
                color="primary"
                x-large
                @click="submitForm()"
                :disabled="remainingSlots <= 0"
                >Anmelden</v-btn
              >
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn text @click="signOff">Ich bin leider verhindert</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>

    <v-card flat class="ma-5" v-else>
      <v-row>
        <v-col align="center" cols="12">
          <v-alert type="info"> Diese Seite existiert nicht </v-alert>
          <v-btn color="primary" @click="$router.push('/')"
            >Zur Startseite</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
  
<script>
import userService from "@/services/userService";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import api from "@/api";

export default {
  data() {
    return {
      loading: true,
      visible: false,
      userService,
      plusOne: "", // Speichert die Eingabe des Benutzers für die Begleitung
      isEmployee: false, // Gibt an, ob der Benutzer ein Mitarbeiter ist
      isRegistered: false, // Gibt an, ob der Benutzer bereits angemeldet ist
      remainingSlots: 0, // Verfügbare Plätze
      interval: null,
      showForwardButton: true,
    };
  },
  methods: {
    async forwardTicket() {
      try {
        // Button vorübergehend verstecken
        this.showForwardButton = false;
        // Kurze Verzögerung, um sicherzustellen, dass der Button unsichtbar ist
        await new Promise((resolve) => setTimeout(resolve, 100));
        // Wähle das HTML-Element, das in ein Bild umgewandelt werden soll
        const element = this.$refs.ticketContainer;

        // Erzeuge das Bild mit html2canvas
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png");

        // Erstellen Sie einen Blob aus dem Base64-String
        const response = await fetch(image);
        const blob = await response.blob();
        const file = new File([blob], "ticket.png", { type: "image/png" });

        // Button wieder anzeigen
        this.showForwardButton = true;

        // Überprüfen, ob Flutter Webview verfügbar ist
        if (window.flutter_inappwebview) {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const dataUrl = reader.result;
            const base64 = dataUrl.split(",")[1];
            window.flutter_inappwebview.callHandler(
              "blobToBase64Handler",
              base64,
              "png",
              "ticket"
            );
          };
        } else {
          // Verwenden Sie die FileSaver-Library oder eine andere Methode, um das Bild herunterzuladen
          saveAs(blob, "ticket.png");
        }
      } catch (error) {
        console.log("HERE", error);
        console.error("Fehler beim Erstellen des Tickets:", error);
        this.$toast.error("Fehler beim Weiterleiten des Tickets");
      }
    },
    async signOffPlusOne() {
      if (confirm("Möchtest du deine Begleitung wirklich abmelden?")) {
        try {
          // Übermitteln der Anmeldedaten an das Backend
          const signupData = {
            signedUp: true,
            employee: this.isEmployee,
            plusOne: null,
          };

          const response = await api.managePartySignup(signupData);

          // Erfolgreiche Anmeldung: Status aktualisieren
          this.isRegistered = true;

          this.plusOne = "keine";
          this.$toast.success("Erfolgreich abgemeldet");
          console.log("Anmeldung erfolgreich:", response);
        } catch (error) {
          this.$toast.error("Fehler bei der Abmeldung");
        }
      }
    },
    async signOff() {
      if (confirm("Möchtest du dich wirklich abmelden?")) {
        try {
          // Übermitteln der Anmeldedaten an das Backend
          const signupData = {
            signedUp: false,
            employee: this.isEmployee,
            plusOne: null,
          };

          const response = await api.managePartySignup(signupData);

          // Erfolgreiche Anmeldung: Status aktualisieren
          this.isRegistered = false;

          this.plusOne = "";
          this.$toast.success("Erfolgreich abgemeldet");
          console.log("Anmeldung erfolgreich:", response);
        } catch (error) {
          this.$toast.error("Fehler bei der Abmeldung");
        }
      }
    },
    async submitForm() {
      try {
        // Übermitteln der Anmeldedaten an das Backend
        const signupData = {
          signedUp: true,
          employee: this.isEmployee,
        };

        // Nur plusOne hinzufügen, wenn der Benutzer ein Mitarbeiter ist
        if (this.isEmployee && this.plusOne) {
          signupData.plusOne = this.plusOne;
        }

        const response = await api.managePartySignup(signupData);

        // Erfolgreiche Anmeldung: Status aktualisieren
        this.isRegistered = true;
        console.log(response);
        this.plusOne = response.data.plusOne || "keine";
        this.$toast.success("Erfolgreich angemeldet");
        console.log("Anmeldung erfolgreich:", response);
      } catch (error) {
        this.$toast.error("Fehler bei der Anmeldung");
      }
    },
    async fetchSlots() {
      try {
        const slotsData = await api.fetchRemainingPartySlots();
        this.remainingSlots = slotsData.remainingSlots;
      } catch (error) {
        console.error("Fehler beim Abrufen der verfügbaren Plätze:", error);
      }
    },
  },
  async created() {
    try {
      // Holen der Verfügbarkeitsdaten für die Party-Anmeldung
      const status = await api.fetchSignupAvailabilityParty();

      if (!status.visible) {
        this.$router.push("/");
      } else {
        this.visible = true;
      }

      await this.fetchSlots();
      // Intervall für das Aktualisieren der Slots alle 15 Sekunden
      this.interval = setInterval(() => {
        this.fetchSlots();
      }, 15000);

      // Daten aus der API-Antwort extrahieren und Zustand setzen
      this.isRegistered = status.signedUp;
      this.plusOne = status.plusOne || ""; // Standardmäßig leer setzen, falls keine Begleitung
      if (this.isRegistered && this.plusOne === "") {
        this.plusOne = "keine";
      }
      this.isEmployee = status.isEmployee;
      this.loading = false;
    } catch (error) {
      console.error("Fehler beim Abrufen des Anmeldestatus:", error);
    }
  },
  beforeDestroy() {
    // Intervall löschen, wenn die Komponente zerstört wird
    console.log("done fetching slots");
    clearInterval(this.interval);
  },
  computed: {
    user() {
      const user = {
        firstName: "",
        lastName: "",
      };
      const tempUser = this.userService.getUser();
      if (tempUser) {
        user.firstName = tempUser.vorname;
        user.lastName = tempUser.nachname;
      }
      return user;
    },
  },
};
</script>

<style scoped>
</style>
